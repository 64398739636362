<script setup lang="ts">
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ContentWidthContainer from "@/components/Global/ContentWidthContainer.vue";
import ResourceLayout from "@/components/Global/ResourceLayout.vue";
import Tabs from "@/components/Global/Tabs.vue";
import TestTable from "@/components/Global/TestTable.vue";
import StandardReport from "@/components/Teacher/StandardReport.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import Resource from "@/types/Resource";
import Test from "@/types/Test";
import { router } from "@inertiajs/vue3";
import { PropType, ref } from "vue";
import ListSubmissions from "@/components/Teacher/ListSubmissions.vue";
import Paginator from "@/types/Paginator";
import Submission from "@/types/Submission";
import { FontAwesomeIcon } from "~/@fortawesome/vue-fontawesome";

const props = defineProps({
    resource: {
        type: Object as PropType<Resource>,
        default: () => ({}),
    },
    tests: {
        type: Array as PropType<Test[]>,
        default: () => [],
    },
    submissions: {
        type: Object as PropType<Paginator<Submission>>,
        default: () => ({}),
    },
    canViewTeachersGuide: {
        type: Boolean,
        default: true,
    },
    tab: {
        type: String,
        default: "tests",
    },
});

const loading = ref(false);

const changeTab = (tab: string) => {
    loading.value = true;
    router.get(
        route("teacher.resource.show-tab", [props.resource, tab]),
        {},
        {
            preserveState: true,
            only: [tab],
            onFinish: (visit) => {
                loading.value = false;
            },
        },
    );
};
</script>

<template>
    <AppLayout>
        <ContentWidthContainer v-if="resource.ebook_only">
            <div class="h-page box-border w-full border border-gray-400 bg-gray-200 py-10">
                <div
                    :style="`background-image: url('${resource.image_url}');`"
                    class="h-full w-full bg-contain bg-center bg-no-repeat"
                >
                    <div class="px-6">
                        <a
                            :href="route('resources.index')"
                            class="hover:underline"
                        >
                            View All Resources
                        </a>
                    </div>

                    <div
                        class="relative mt-2 inline-block w-full bg-red-600 px-6 py-4 drop-shadow-lg lg:w-3/4 xl:w-[60%]"
                    >
                        <h1 class="mb-2 text-2xl text-white">{{ resource.title }}</h1>

                        <div class="mt-4 flex flex-col gap-y-2">
                            <BtnPrimary :href="route('teacher.codes.index', { resource: resource.id })">
                                Manage Codes
                            </BtnPrimary>

                            <BtnPrimary :href="route('resources.eBook', { resource: resource.id })">
                                View eBook
                            </BtnPrimary>

                            <BtnPrimary
                                :href="route('resources.teacher-guide', { resource: resource.id })"
                                v-if="resource.teacher_guide_toc?.length > 0"
                            >
                                View Teacher's Guide
                            </BtnPrimary>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWidthContainer>

        <ResourceLayout
            :resource="resource"
            v-else
        >
            <template #sidebar>
                <div class="mt-4 flex flex-col gap-y-2">
                    <BtnPrimary :href="route('teacher.codes.index', { resource: resource.id })">
                        Manage Codes
                    </BtnPrimary>

                    <BtnPrimary
                        v-if="resource.ebook_toc?.length > 0"
                        :href="route('resources.eBook', { resource: resource.id })"
                        target="_blank"
                        aria-label="Open in a new tab"
                    >
                        View eBook
                    </BtnPrimary>

                    <BtnPrimary
                        v-if="canViewTeachersGuide && resource.teacher_guide_toc?.length > 0"
                        :href="route('resources.teacher-guide', { resource: resource.id })"
                        target="_blank"
                        aria-label="Open in a new tab"
                    >
                        View Teacher's Guide
                    </BtnPrimary>
                </div>
            </template>

            <Tabs
                :tabs="{
                    tests: 'Tests',
                    submissions: 'Submissions',
                    standards: 'Standard Report',
                }"
                :initial-active-tab="tab"
                @change-tab="changeTab"
            >
                <template #tests>
                    <div v-show="!loading">
                        <TestTable :tests="tests" />
                    </div>

                    <div
                        v-show="loading"
                        class="py-10 text-center text-2xl"
                    >
                        <FontAwesomeIcon
                            icon="spinner"
                            class="animate-spin"
                        />
                        Loading...
                    </div>
                </template>

                <template #submissions>
                    <ListSubmissions
                        v-show="!loading"
                        :resource="resource"
                        :submissions-data="submissions"
                        :show-pagination="true"
                        :show-email-students="false"
                        class="mt-6"
                    />

                    <div
                        v-show="loading"
                        class="py-10 text-center text-2xl"
                    >
                        <FontAwesomeIcon
                            icon="spinner"
                            class="animate-spin"
                        />
                        Loading...
                    </div>
                </template>

                <template #standards>
                    <StandardReport
                        v-show="!loading"
                        :tests="tests"
                        class="mt-2"
                    />

                    <div
                        v-show="loading"
                        class="py-10 text-center text-2xl"
                    >
                        <FontAwesomeIcon
                            icon="spinner"
                            class="animate-spin"
                        />
                        Loading...
                    </div>
                </template>
            </Tabs>
        </ResourceLayout>
    </AppLayout>
</template>
