<script setup lang="ts">
import { PropType, ref } from "vue";
import User from "@/types/User";
import axios from "axios";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

const props = defineProps({
    user: {
        type: Object as PropType<User>,
        required: true,
    },
});

const { route } = window;
const formProfile = ref({
    processing: false,
    saved: false,
    email: props.user.email,
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    display_name: props.user.display_name,
});

const errorMsg = ref("");
function saveSettings() {
    formProfile.value.processing = true;
    errorMsg.value = "";
    axios
        .post(route("save-settings"), {
            email: formProfile.value.email,
            first_name: formProfile.value.first_name,
            last_name: formProfile.value.last_name,
            display_name: formProfile.value.display_name,
        })
        .then(() => {
            formProfile.value.saved = true;
            setTimeout(() => {
                formProfile.value.saved = false;
            }, 5000);
        })
        .catch((error) => {
            if (error.response.status === 422) {
                const { errors } = error.response.data;
                // Loop through the errors and add them to the error message
                if (errors) {
                    for (const key in errors) {
                        errorMsg.value += `${errors[key][0]} `;
                    }
                }
            }
        })
        .finally(() => {
            formProfile.value.processing = false;
        });
}
</script>

<template>
    <form
        @submit.prevent="saveSettings"
        class="card flex h-full flex-col items-stretch"
    >
        <div class="flex flex-1 flex-col items-start justify-between p-4">
            <p
                v-if="formProfile.saved"
                class="relative mb-4 w-full rounded border border-green-400 bg-green-100 px-4 py-3 text-green-700"
                role="alert"
            >
                <strong>Success!</strong> Your settings have been updated.
            </p>

            <p
                v-else-if="errorMsg"
                class="relative mb-4 w-full rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                role="alert"
            >
                {{ errorMsg }}
            </p>

            <div class="w-full flex-grow space-y-4">
                <div class="w-full">
                    <label
                        for="email"
                        class="col-md-4 col-form-label text-md-right"
                        >E-Mail Address</label
                    >

                    <div class="">
                        <input
                            v-model="formProfile.email"
                            id="email"
                            type="email"
                            name="email"
                            autocomplete="email"
                            autofocus
                            class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                            required
                        />
                    </div>
                </div>

                <div class="w-full">
                    <label
                        for="first_name"
                        class="col-md-4 col-form-label text-md-right"
                        >First Name</label
                    >

                    <div class="">
                        <input
                            v-model="formProfile.first_name"
                            id="first_name"
                            type="text"
                            name="first_name"
                            autocomplete="first_name"
                            autofocus
                            class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                            required
                        />
                    </div>
                </div>

                <div class="w-full">
                    <label
                        for="last_name"
                        class="col-md-4 col-form-label text-md-right"
                        >Last Name</label
                    >

                    <div class="">
                        <input
                            v-model="formProfile.last_name"
                            id="last_name"
                            type="text"
                            name="last_name"
                            autocomplete="last_name"
                            autofocus
                            class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                            required
                        />
                    </div>
                </div>

                <div class="w-full">
                    <label
                        for="display_name"
                        class="col-md-4 col-form-label text-md-right"
                    >
                        Display Name
                        <span class="text-gray-600">Name shown to student</span>
                    </label>

                    <div class="">
                        <input
                            v-model="formProfile.display_name"
                            id="display_name"
                            type="text"
                            name="display_name"
                            autocomplete="display_name"
                            autofocus
                            class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                        />
                    </div>
                </div>
            </div>

            <BtnPrimary
                class="mx-auto mt-8 w-full xl:w-auto xl:px-12"
                :disabled="formProfile.processing"
            >
                Update Profile
            </BtnPrimary>
        </div>
    </form>
</template>

<style scoped></style>
