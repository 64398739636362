<script setup>
import { router } from "@inertiajs/vue3";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import BtnDanger from "@/components/Global/BtnDanger.vue";
import Swal from "sweetalert2";
import { ref } from "vue";
import MasterResourceList from "@/components/Global/MasterResourceList.vue";

defineProps({});

const masterResourceListId = ref(`mrl-${Math.random()}`);
const processingArchive = ref(false);

const getQueryParams = () => {
    const entries = new URLSearchParams(window.location.search).entries();

    return Object.fromEntries(entries);
};

const archiveResource = (resource) => {
    Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        confirmButtonText: "Yes, archive it",
        showCancelButton: true,
        showConfirmButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            processingArchive.value = true;
            const queryParams = getQueryParams();

            router.delete(route("test-creator.resources.destroy", { resource, ...queryParams }), {
                preserveScroll: true,
                onFinish: () => {
                    masterResourceListId.value = `mrl-${Math.random()}`; // Change ID to refresh the component
                    processingArchive.value = false;
                },
            });
        }
    });
};

const restoreResource = (resource) => {
    Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        confirmButtonText: "Yes, restore it",
        showCancelButton: true,
        showConfirmButton: true,
    }).then((result) => {
        if (result.isConfirmed) {
            processingArchive.value = true;
            const queryParams = getQueryParams();

            router.post(
                route("test-creator.resources.restore", { resource, ...queryParams }),
                {},
                {
                    preserveScroll: true,
                    onFinish: () => {
                        masterResourceListId.value = `mrl-${Math.random()}`; // Change ID to refresh the component
                        processingArchive.value = false;
                    },
                },
            );
        }
    });
};
</script>

<template>
    <div>
        <div
            v-show="processingArchive"
            class="fixed left-0 top-0 z-50 flex h-dvh w-full items-center justify-center bg-gray-200 text-center text-2xl opacity-50"
        >
            <FontAwesomeIcon
                icon="sync"
                spin
                class="mr-2"
            />
            Loading
        </div>

        <MasterResourceList
            :key="masterResourceListId"
            :hide-archived-checkbox="false"
        >
            <template #row="{ resource }">
                <div class="w-48">
                    <a :href="route('resources.show', [resource])">
                        <img
                            class="mx-auto h-auto max-h-40 p-2 sm:max-h-full"
                            :src="resource.image_url"
                            :alt="`Resource  ${resource.id}`"
                        />
                    </a>
                </div>

                <a
                    :href="route('resources.show', [resource])"
                    class="w-full flex-auto border-0 p-2"
                >
                    <div class="card-body">
                        <h3 class="text-2xl">{{ resource.title }}</h3>

                        <p class="text-sm">Number of Tests: {{ resource.test_count }}</p>
                    </div>
                </a>

                <slot
                    name="actions"
                    :resource="resource"
                >
                    <div class="flex w-72 items-center justify-center p-2">
                        <div
                            class="grid grid-cols-2 gap-3"
                            v-cloak
                        >
                            <BtnPrimary
                                class=""
                                :href="route('test-creator.resources.edit', [resource])"
                            >
                                Edit
                            </BtnPrimary>

                            <BtnDanger
                                class=""
                                v-if="!resource.archived_at"
                                @click.prevent="archiveResource(resource)"
                            >
                                Archive
                            </BtnDanger>

                            <BtnDanger
                                class=""
                                v-else
                                @click.prevent="restoreResource(resource)"
                            >
                                Unarchive
                            </BtnDanger>
                        </div>
                    </div>
                </slot>
            </template>
        </MasterResourceList>
    </div>
</template>
