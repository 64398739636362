<script setup lang="ts">
import { computed, ref, useAttrs } from "vue";
import findIndex from "lodash/findIndex";
import values from "lodash/values";
import VueFilePond from "vue-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import MediaCard from "@/components/TestCreation/MediaLibrary/MediaCard.vue";

const props = defineProps({
    media: {
        type: Array,
        required: true,
    },
    resource: {
        type: Object,
        required: true,
    },
});

const FilePond = VueFilePond(
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
);

const clearQueueTimeout = ref(null);
const media = ref([...values(props.media)]);
const upload = ref(false);

const uploadUrl = computed(() => window.location.pathname);
const csrfToken = computed(() => useAttrs().csrf_token);
const uploadLabel = computed(() =>
    media.value.length === 0
        ? "No files have been uploaded for this resource. <br/> Please click here or drop files here..."
        : "Drop files here...",
);

const pond = ref(null);

function queueComplete() {
    clearQueueTimeout.value = setTimeout(() => {
        pond.value.removeFiles();
        upload.value = false;

        window.location.reload();
    }, 2000);
}

function removeItem(item) {
    const index = findIndex(media.value, { id: item.id });

    media.value.splice(index, 1);
}

function stopClearQueue() {
    if (clearQueueTimeout.value) {
        clearTimeout(clearQueueTimeout.value);
    }
}
</script>

<template>
    <div
        class="relative h-full w-full"
        @dragenter="upload = true"
    >
        <div
            class="-mx-6 mb-12 flex flex-wrap p-6"
            v-if="media.length"
        >
            <MediaCard
                :media-item="mediaItem"
                :resource="resource"
                v-for="mediaItem in media"
                :key="mediaItem.id"
                @deleted="removeItem"
            >
            </MediaCard>
        </div>

        <div
            class="absolute top-0 mb-1 h-full w-full"
            id="media-library-upload"
            v-show="media.length === 0 || upload"
        >
            <FilePond
                name="file"
                ref="pond"
                :label-idle="uploadLabel"
                allow-multiple="true"
                drop-on-page="true"
                drop-on-element="false"
                @processfiles="queueComplete"
                @addfile="stopClearQueue"
                accepted-file-types="image/*"
                max-file-size="10MB"
                :server="{
                    // url: uploadUrl,
                    // process: {
                    //     headers: {
                    //         'X-CSRF-TOKEN': csrfToken,
                    //     },
                    // },

                    url: uploadUrl,
                    method: 'POST',
                    headers: {
                        'X-CSRF-TOKEN': csrfToken,
                        Accept: 'application/json',
                    },
                    process: {
                        onload: (result) => {
                            console.log('File uploaded...... uploadURL: ', uploadUrl);
                            console.log('File uploaded...... csrfToken: ', csrfToken);
                        },
                    },
                }"
            />
        </div>
    </div>
</template>
