<script>
import VueFilePond from "vue-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Loading from "vue-loading-overlay";
import CSelect from "@/components/Form/CSelect.vue";
import CCheckbox from "@/components/Form/CCheckbox.vue";
import CText from "@/components/Form/CText.vue";
import Constants from "@/Constants";
import Form from "@/Form";
import "vue-loading-overlay/dist/css/index.css";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import Swal from "sweetalert2";

const FilePond = VueFilePond(FilePondPluginImagePreview);
const FilePondEbook = VueFilePond(FilePondPluginFileValidateType);

export default {
    data() {
        return {
            editingImage: false,
            files: null,
            showEbookFilePond: true,
            ebook: null,
            showTeacherGuideFilePond: true,
            teacherGuide: null,
            form: new Form({
                published: 0,
                title: "",
                topic: "",
                states: [],
                grades: [],
                deleteEbook: false,
                deleteTeacherGuide: false,
                keyboard: "",
                ebook_samples: "",
            }),
            message: "",
            saving: false,
            topics: Constants.topics,
            states: Constants.states,
            grades: Constants.grades,
            keyboards: Constants.keyboards,
            allStates: false,
        };
    },

    components: {
        BtnPrimary,
        CText,
        CCheckbox,
        CSelect,
        FilePond,
        FilePondEbook,
        Loading,
    },

    props: {
        resource: {
            default() {
                return {};
            },
        },
    },

    computed: {
        creating() {
            return this.resource.created_at === undefined;
        },

        errors() {
            return this.form.errors;
        },
    },

    watch: {
        // Mark all/clear states
        allStates(val) {
            if (val) {
                this.form.states = Object.keys(this.states);
            } else {
                this.form.states = [];
            }
        },
    },

    methods: {
        addFile(errors, data) {
            this.errors.clear("photo");

            this.files = [data.file];
        },

        cancel() {
            this.editingImage = false;
            this.$refs.pond.removeFile();
        },

        editImage() {
            this.editingImage = true;
        },

        // --- eBook FilePond ---
        cancelEbook() {
            this.showEbookFilePond = false;
        },

        // --- Teacher's guide FilePond ---
        cancelTeacherGuide() {
            this.showTeacherGuideFilePond = false;
        },

        filePondServerConfig(ref) {
            // inertia CSRF token
            const csrfToken = this.$page.props.csrf_token;

            const server = {
                url: route("test-creator.resources.upload-ebook"),
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                },
                process: {
                    onload: (result) => {
                        const serverId = result.response;
                        this.$refs[ref].serverId = serverId;
                        return serverId;
                    },
                },
            };

            return server;
        },

        save() {
            this.saving = true;
            const formData = new FormData();

            _.forIn(this.form.data(), (value, key) => {
                if (value) {
                    formData.append(key, value);
                }
            });

            // Resource image
            if (this.files && this.files.length) {
                const file = _.first(this.files);
                formData.append("photo", file, file.name);
            }

            // eBook
            if (!this.form.deleteEbook && this.$refs.ebook?.serverId) {
                formData.append("ebookServerId", this.$refs.ebook.serverId);
            }
            // Teacher's guide
            if (!this.form.deleteTeacherGuide && this.$refs.teacherGuide?.serverId) {
                formData.append("teacherGuideServerId", this.$refs.teacherGuide.serverId);
            }

            // Create
            if (this.resource.id === undefined) {
                this.form
                    .post(route("test-creator.resources.store"), formData)
                    .then((data) => {
                        this.$emit("created", data);
                    })
                    .catch((error) => {
                        this.showErrorMessage(error);
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            } // Update
            else {
                formData.append("_method", "PATCH");
                this.form
                    .post(route("test-creator.resources.update", this.resource), formData)
                    .then((data) => {
                        this.$emit("updated", data);
                    })
                    .catch((error) => {
                        this.showErrorMessage(error);
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            }
        },

        showErrorMessage(error) {
            console.log(error);
            Swal.fire({
                title: "Whoops!",
                html: `There was an unexpected error. Please address the issue and try again.<div class="mt-4 italic text-red-500">${error.message}</div>`,
                icon: "error",
            });
        },
    },

    created() {
        if (this.resource.id !== undefined) {
            this.resource.grades = this.resource.grades ?? [];
            this.resource.states = this.resource.states ?? [];
            this.resource.ebook_samples = this.resource.ebook_samples ?? "";
            this.form.load(this.resource);
        } else {
            this.editingImage = true;
        }

        if (this.resource && this.resource.ebook_toc) {
            this.showEbookFilePond = false;
        }
        if (this.resource && this.resource.teacher_guide_toc) {
            this.showTeacherGuideFilePond = false;
        }
    },

    mounted() {
        if (this.resource.id !== undefined) {
            // this.$refs.pond.addFile(this.resource.image_url); // NOTE: I'm not sure why we need to do this?
        }
    },
};
</script>

<template>
    <div class="flex w-full flex-wrap content-start">
        <Loading
            v-model:active="saving"
            :can-cancel="false"
            :is-full-page="true"
        />

        <!-- Left panel -->
        <div class="w-full pr-4 sm:w-1/2 md:w-1/4 lg:w-1/5 xl:w-1/6">
            <!-- Resource photo-->
            <div class="group relative">
                <button
                    v-if="!creating && !editingImage"
                    @click="editImage()"
                    class="absolute right-2 top-2 z-10 rounded-full bg-gray-300 bg-opacity-25 px-3 py-2 text-black group-hover:bg-opacity-50"
                >
                    <FontAwesomeIcon icon="pencil-alt" />
                </button>

                <button
                    v-else-if="!creating"
                    @click="cancel()"
                    class="absolute right-2 top-2 z-10 rounded-full bg-gray-300 bg-opacity-25 px-3 py-2 text-black group-hover:bg-opacity-50"
                >
                    <FontAwesomeIcon icon="times" />
                </button>

                <div
                    v-show="editingImage"
                    class="pt-14"
                >
                    <FilePond
                        ref="pond"
                        :instant-upload="false"
                        :files="files"
                        @addfile="addFile"
                    />
                </div>

                <img
                    v-show="!editingImage"
                    :src="resource.image_url"
                    :alt="resource.title"
                    class="w-full object-cover"
                />

                <span
                    class="mt-1 text-xs text-red-700"
                    role="alert"
                    v-if="errors.has('photo')"
                >
                    <strong>{{ errors.get("photo") }}</strong>
                </span>
            </div>
            <!-- Resource ebook-->
            <hr class="my-6" />

            <h3 class="text-right">eBook</h3>

            <div class="relative text-right">
                <div v-if="!showEbookFilePond && resource && resource.ebook_toc">
                    <a
                        v-if="!form.deleteEbook"
                        :href="route('resources.eBook', resource.id)"
                        target="_blank"
                        aria-label="Open in a new tab"
                        class="mr-2 rounded-full bg-gray-300 bg-opacity-25 px-3 py-2 text-black hover:bg-opacity-50 hover:underline"
                    >
                        <FontAwesomeIcon icon="book" />
                    </a>

                    <button
                        v-if="!form.deleteEbook"
                        @click="showEbookFilePond = !showEbookFilePond"
                        class="mr-2 rounded-full bg-gray-300 bg-opacity-25 px-3 py-2 text-black hover:bg-opacity-50"
                    >
                        <FontAwesomeIcon icon="pencil-alt" />
                    </button>

                    <button
                        @click="form.deleteEbook = !form.deleteEbook"
                        class="mr-2 rounded-full bg-opacity-25 px-3 py-2 text-black hover:bg-opacity-50"
                        :class="form.deleteEbook ? 'bg-gray-500' : 'bg-gray-300'"
                    >
                        <FontAwesomeIcon icon="trash" />
                    </button>
                </div>

                <div v-else>
                    <button
                        v-if="resource && resource.ebook_toc"
                        @click="cancelEbook"
                        class="absolute right-2 z-10 rounded-full bg-gray-300 bg-opacity-25 px-3 py-2 text-black hover:bg-opacity-50"
                    >
                        <FontAwesomeIcon icon="times" />
                    </button>

                    <div :class="{ 'pt-12': resource && resource.ebook_toc }">
                        <FilePondEbook
                            ref="ebook"
                            label-idle="Drop eBook here"
                            :allow-remove="true"
                            :allow-multiple="false"
                            accepted-file-types="application/pdf"
                            :file="ebook"
                            :instant-upload="true"
                            :chunk-uploads="true"
                            :chunk-force="true"
                            :server="filePondServerConfig('ebook')"
                            @addfilestart="saving = true"
                            @processfile="saving = false"
                            @removefile="$refs['ebook'].serverId = null"
                        />
                    </div>
                </div>
            </div>

            <!-- Teacher's guide -->
            <hr class="my-6" />

            <h3 class="text-right">Teacher's Guide</h3>

            <div class="relative text-right">
                <div v-if="!showTeacherGuideFilePond && resource && resource.teacher_guide_toc">
                    <a
                        v-if="!form.deleteTeacherGuide"
                        :href="route('resources.teacher-guide', resource.id)"
                        target="_blank"
                        aria-label="Open in a new tab"
                        class="mr-2 rounded-full bg-gray-300 bg-opacity-25 px-3 py-2 text-black hover:bg-opacity-50 hover:underline"
                    >
                        <FontAwesomeIcon icon="book" />
                    </a>

                    <button
                        v-if="!form.deleteTeacherGuide"
                        @click="showTeacherGuideFilePond = !showTeacherGuideFilePond"
                        class="mr-2 rounded-full bg-gray-300 bg-opacity-25 px-3 py-2 text-black hover:bg-opacity-50"
                    >
                        <FontAwesomeIcon icon="pencil-alt" />
                    </button>

                    <button
                        @click="form.deleteTeacherGuide = !form.deleteTeacherGuide"
                        class="mr-2 rounded-full bg-opacity-25 px-3 py-2 text-black hover:bg-opacity-50"
                        :class="form.deleteTeacherGuide ? 'bg-gray-500' : 'bg-gray-300'"
                    >
                        <FontAwesomeIcon icon="trash" />
                    </button>
                </div>

                <div v-else>
                    <button
                        v-if="resource && resource.teacher_guide_toc"
                        @click="cancelTeacherGuide"
                        class="absolute right-2 z-10 rounded-full bg-gray-300 bg-opacity-25 px-3 py-2 text-black hover:bg-opacity-50"
                    >
                        <FontAwesomeIcon icon="times" />
                    </button>

                    <div :class="{ 'pt-12': resource && resource.teacher_guide_toc }">
                        <FilePondEbook
                            ref="teacherGuide"
                            label-idle="Drop teacher's guide here"
                            :allow-remove="true"
                            :allow-multiple="false"
                            accepted-file-types="application/pdf"
                            :file="teacherGuide"
                            :instant-upload="true"
                            :chunk-uploads="true"
                            :chunk-force="true"
                            :server="filePondServerConfig('teacherGuide')"
                            @addfilestart="saving = true"
                            @processfile="saving = false"
                            @removefile="$refs['teacherGuide'].serverId = null"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- Right panel -->
        <div class="h-full w-full sm:w-1/2 md:w-3/4 lg:w-4/5 xl:w-5/6">
            <div class="mb-1">
                <CCheckbox
                    label="Publish This Resource"
                    input-value="1"
                    v-model="form.published"
                    class="block md:w-2/3"
                ></CCheckbox>
            </div>

            <!-- Title, topic, keyboard -->
            <div class="mb-2 grid w-full lg:grid-cols-2">
                <div class="lg:mr-2">
                    <CText
                        v-model="form.title"
                        placeholder="Enter title..."
                        :required="true"
                    />

                    <span
                        class="h-4 text-xs text-red-700"
                        role="alert"
                        v-if="errors.has('title')"
                    >
                        <strong>{{ errors.get("title") }}</strong>
                    </span>
                </div>

                <div class="grid lg:grid-cols-2">
                    <div class="lg:mr-2">
                        <CSelect
                            :placeholder="'Select Subject...'"
                            :options="topics"
                            v-model="form.topic"
                        />
                    </div>

                    <div>
                        <CSelect
                            :placeholder="'Select Keyboard...'"
                            :options="keyboards"
                            v-model="form.keyboard"
                        />
                    </div>
                </div>
            </div>

            <div class="mb-2">
                <label class="text-sm font-light italic text-gray-500">eBook Sample Examples: 2-3; 5-10; 100;</label>

                <CText
                    v-model="form.ebook_samples"
                    placeholder="eBook Sample Ranges..."
                />
            </div>

            <!-- States and grades -->
            <div class="grid w-full rounded border border-gray-300 p-2 md:grid-cols-2">
                <div class="mr-2 border-r">
                    <label class="block">States:</label>

                    <CCheckbox
                        :label="'Select All'"
                        :input-value="true"
                        v-model="allStates"
                        class="block md:w-2/3"
                    />

                    <div class="grid md:grid-cols-2">
                        <div
                            v-for="(val, key) in states"
                            class=""
                        >
                            <CCheckbox
                                :label="val"
                                :input-value="key"
                                v-model="form.states"
                                class="block md:w-2/3"
                            ></CCheckbox>
                        </div>
                    </div>
                </div>

                <div>
                    <label class="block">Grades:</label>

                    <div class="grid md:grid-cols-2">
                        <div
                            v-for="(val, key) in grades"
                            class=""
                        >
                            <CCheckbox
                                :label="val"
                                :input-value="key"
                                v-model="form.grades"
                                class="block md:w-2/3"
                            ></CCheckbox>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Buttons -->
            <div class="mt-4 flex flex-row-reverse">
                <div class="ml-2 flex-initial">
                    <BtnPrimary
                        @click="save"
                        :disabled="saving || errors.any()"
                    >
                        <span v-if="!saving && resource.id === undefined">Save</span>

                        <span v-else-if="!saving && resource.id">Update</span>

                        <FontAwesomeIcon
                            v-else
                            icon="circle-notch"
                            spin
                        />
                    </BtnPrimary>
                </div>

                <div class="flex-initial">
                    <slot
                        name="cancel"
                        :saving="saving"
                        :title-errors="errors.has('title')"
                    ></slot>
                </div>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<style>
.filepond--root {
    margin-bottom: 0px;
}
.filepond--credits {
    display: none;
}
</style>
