<script lang="ts" setup>
import { Head, useForm } from "@inertiajs/vue3";
import { onMounted, PropType, ref } from "vue";
import AppLayout from "@/layouts/AppLayout.vue";
import Resource from "@/types/Resource";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

const props = defineProps({
    resource: {
        type: Object as PropType<Resource>,
        default: () => ({}),
    },
});

const titleInput = ref<HTMLInputElement>();

const form = useForm(() => ({
    title: props.resource?.title ?? "",
}));

const submitForm = () => {
    form.post(route("test-creator.tests.store", [props.resource]));
};

onMounted(() => {
    titleInput.value?.focus();
});
</script>

<template>
    <AppLayout>
        <Head title="Test - Create" />

        <form
            class="mx-auto flex h-full flex-wrap content-start p-6"
            @submit.prevent="submitForm"
        >
            <div class="w-full">
                <label
                    for="email"
                    class="col-md-4 col-form-label text-md-right"
                >
                    Test Title
                </label>

                <input
                    id="title"
                    type="text"
                    name="title"
                    ref="titleInput"
                    v-model="form.title"
                    autofocus
                    class="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:shadow-outline focus:outline-none"
                />

                <span
                    class="text-xs text-red-700"
                    role="alert"
                    v-if="form.errors.title"
                >
                    <strong>{{ form.errors.title }}</strong>
                </span>
            </div>

            <div class="mt-3 flex w-full flex-wrap items-center justify-between">
                <BtnPrimary
                    type="input"
                    class="w-full flex-grow"
                >
                    Create
                </BtnPrimary>
            </div>
        </form>
    </AppLayout>
</template>
