<script>
import CSelect from "../Form/CSelect.vue";

export default {
    components: { CSelect },
    data() {
        return {
            edit: false,
            pageOptions: {},
            ebook_page_number: 0,
        };
    },

    props: {
        test: {},
        resource: {},
        editing: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        save() {
            const method = "patch";
            const url = route("test-creator.tests.update", [this.resource, this.test]);
            this.test.ebook_page_number = this.ebook_page_number ?? null;

            axios
                .request({ method, url, data: this.test })
                .then(({ data }) => {
                    this.edit = false;
                })
                .catch((errors) => {
                    console.error(errors);
                });
        },

        startEditing() {
            this.edit = true;
            this.$nextTick(() => {
                // this.$refs.title.focus();
                // this.moveCursorToEnd(this.$refs.title);
            });
        },

        moveCursorToEnd(el) {
            el.focus();
            if (typeof window.getSelection !== "undefined" && typeof document.createRange !== "undefined") {
                const range = document.createRange();
                range.selectNodeContents(el);
                range.collapse(false);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            } else if (typeof document.body.createTextRange !== "undefined") {
                const textRange = document.body.createTextRange();
                textRange.moveToElementText(el);
                textRange.collapse(false);
                textRange.select();
            }
        },

        updateTitle(e) {
            this.test.title = e.target.innerText;
        },

        createTocOptions(toc, options = {}, level = 0) {
            if (toc?.length > 0) {
                toc.forEach((obj) => {
                    if (!options.hasOwnProperty(obj.pageNumber)) {
                        let paddingLeft = "";
                        for (let i = 0; i < level; i++) {
                            paddingLeft += "&nbsp;&nbsp;&nbsp;&nbsp;";
                        }
                        options[obj.pageNumber] = paddingLeft + obj.title;
                    }
                    if (obj.items?.length > 0) {
                        options = this.createTocOptions(obj.items, options, level + 1);
                    }
                });
            }
            return options;
        },
    },

    mounted() {
        if (this.resource.ebook_toc && this.resource.ebook_toc.length > 0) {
            this.pageOptions = this.createTocOptions(this.resource.ebook_toc, {}, 0);
            this.ebook_page_number = this.test.ebook_page_number ?? null;
        }

        if (this.editing) {
            this.startEditing();
        }
    },
};
</script>

<template>
    <div
        class="relative xl:flex"
        :class="edit ? 'pb-2' : ''"
    >
        <div class="flex-1">
            <h3
                ref="title"
                :contenteditable="edit"
                @blur="updateTitle"
                class="flex-grow text-2xl"
                :class="edit ? 'rounded border border-gray-300 px-3 focus:shadow focus:outline-none focus:ring' : ''"
            >
                {{ test.title }}
            </h3>
        </div>

        <div
            v-if="resource.ebook_toc"
            class="hidden flex-none px-4 xl:block"
        >
            <div class="h-[90%] border-l border-gray-400"></div>
        </div>

        <div
            v-if="resource.ebook_toc"
            class="relative mt-2 flex-1 xl:mt-0"
        >
            <CSelect
                v-if="edit"
                placeholder="Select start page..."
                :options="pageOptions"
                v-model="ebook_page_number"
            />

            <div
                v-else-if="test.ebook_page_number"
                class="absolute bottom-[5px]"
            >
                eBook:
                <span
                    class="text-gray-700"
                    v-html="String(pageOptions[test.ebook_page_number]).replaceAll('&nbsp;', '')"
                ></span>
            </div>

            <div v-else>No eBook page selected</div>
        </div>

        <!-- Action buttons-->
        <div
            v-if="edit"
            class="flex-none pl-2 flex"
        >
            <button
                class="mr-2"
                @click="save"
                aria-label="Save Changes"
                data-balloon-pos="down-right"
            >
                <i class="fas fa-save w-5 hover:text-gray-800"></i>
            </button>

            <button
                class="px-1"
                @click="edit = false"
                aria-label="Cancel Editing"
                data-balloon-pos="down-right"
            >
                <FontAwesomeIcon
                    icon="times"
                    class="hover:text-gray-800"
                ></FontAwesomeIcon>
            </button>
        </div>

        <div
            v-else
            class="absolute -top-6 right-0 flex-none xl:static"
        >
            <button
                @click="startEditing"
                aria-label="Edit test name"
                data-balloon-pos="down-right"
            >
                <FontAwesomeIcon
                    icon="edit"
                    class="hover:text-gray-800"
                ></FontAwesomeIcon>
            </button>
        </div>
    </div>
</template>
