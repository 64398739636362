<script setup lang="ts">
import CodeImport from "@/components/Teacher/CodeImport.vue";
import ResourceCard from "@/components/Global/ResourceCard.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import PaginationDefault from "@/components/Global/paginationDefault.vue";
import ContentWidthContainer from "@/components/Global/ContentWidthContainer.vue";
import Resource from "@/types/Resource";
import { PropType, ref } from "vue";
import Pagination from "@/components/TestTaking/Pagination.vue";
import { router } from "@inertiajs/vue3";
import Loading from "vue-loading-overlay";

const props = defineProps({
    resources: {
        type: Object as PropType<Pagination<Resource>>,
        required: true,
    },
});

const page = ref(1);
const loading = ref(false);

async function fetchData() {
    const paramsStr = `?page=${page.value}`;

    loading.value = true;

    router.get(
        `${window.location.pathname}${paramsStr}`,
        {},
        {
            preserveState: true,
            only: ["resources"],
            onFinish: () => {
                loading.value = false;
            },
        },
    );
}

function goToPage(newPage = 1) {
    if (newPage > 0) {
        page.value = newPage;
        fetchData();
    }
}
</script>

<template>
    <AppLayout>
        <ContentWidthContainer class="w-full p-6">
            <div class="card relative">
                <h2 class="text-2xl">
                    Resources

                    <CodeImport></CodeImport>
                </h2>

                <div
                    v-show="loading"
                    class="absolute z-10 flex h-full w-full items-center justify-center bg-light-background py-40 opacity-90"
                >
                    <div class="flex-none">
                        <Loading
                            v-model:active="loading"
                            :can-cancel="false"
                            :is-full-page="true"
                            class="inline-block"
                        />

                        <br />Loading...
                    </div>
                </div>

                <div class="mt-10 grid grid-cols-6">
                    <ResourceCard
                        v-for="resource in resources.data"
                        :key="resource.id"
                        :resource="resource"
                        route-name="resources.show"
                        class="m-2 border hover:border-gray-400"
                    ></ResourceCard>
                </div>

                <PaginationDefault
                    :data="resources"
                    @go-to-page-clicked="goToPage"
                    class="mt-6 flex justify-center"
                />
            </div>
        </ContentWidthContainer>
    </AppLayout>
</template>
