<script lang="ts" setup>
import { onMounted, PropType } from "vue";
import { Head } from "@inertiajs/vue3";
import Page from "@/types/Page";
import Resource from "@/types/Resource";
import Test from "@/types/Test";
import AppLayout from "@/layouts/AppLayout.vue";
import { render } from "@/library/tinymce";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import CopyButton from "@/components/Global/CopyButton.vue";
import TestForm from "@/components/TestCreation/TestForm.vue";
import TestEditor from "@/components/TestCreation/TestEditor.vue";

// const { VITE_TINYMCE_API_KEY: tinymceKey } = import.meta.env;

const props = defineProps({
    editing: {
        type: Boolean,
        default: false,
    },

    hasSamples: {
        type: Boolean,
        default: false,
    },

    pages: {
        type: Array as PropType<Page[]>,
    },

    resource: {
        type: Object as PropType<Resource>,
        default: () => ({}),
    },

    test: {
        type: Object as PropType<Test>,
        default: () => ({}),
    },
});

const renderTinyMCE = () => {
    render({
        selector: ".short-essay .answer textarea",
        menubar: false,
        plugins: ["lists", "charmap", "anchor", "searchreplace", "fullscreen", "wordcount"],
        toolbar: "bold italic underline | bullist numlist outdent indent | undo redo | formatselect",
        readonly: 1,
    });
};

onMounted(() => {
    renderTinyMCE();
});
</script>

<template>
    <AppLayout>
        <Head title="Test - Edit" />

        <div class="mx-auto flex h-full flex-wrap content-start p-6">
            <div class="z-50 flex w-full">
                <BtnPrimary
                    :href="route('resources.show', [resource])"
                    class="mr-2"
                >
                    <i class="fas fa-chevron-left"></i>
                </BtnPrimary>

                <div class="w-full">
                    <div class="w-full border-b border-black">
                        <TestForm
                            :test="test"
                            :resource="resource"
                            :editing="editing"
                            v-cloak
                        />
                    </div>

                    <div
                        class="h-4 w-full text-sm"
                        v-if="hasSamples"
                    >
                        <h4 class="h-4 w-full text-sm">
                            Sample Url:
                            {{ route("sample-test.test", [test.resource_id, test]) }}

                            <CopyButton
                                class="inline"
                                right
                                size="xs"
                                label-position="down"
                                :value="route('sample-test.test', [test.resource_id, test])"
                            ></CopyButton>
                        </h4>
                    </div>
                </div>
            </div>

            <div class="w-full pt-6">
                <!--                <test-editor-->
                <!--                    :initial-pages="pages"-->
                <!--                    :test="test"-->
                <!--                    @update-has-samples="updateHasSamples"-->
                <!--                />-->

                <TestEditor
                    :initial-pages="pages"
                    :test="test"
                />
            </div>
        </div>
    </AppLayout>
</template>
