<script>
export default {
    data() {
        return {
            deleting: false,
        };
    },

    computed: {
        isPortrait() {
            return this.mediaItem.orientation === "portrait";
        },

        imageClasses() {
            return [
                "mx-auto",
                "items-center",
                this.isPortrait ? "w-auto" : "w-64",
                this.isPortrait ? "h-64" : "h-auto",
            ];
        },
    },

    props: {
        mediaItem: {
            default() {
                return {};
            },
        },

        resource: {},
    },

    methods: {
        deleteMedia() {
            if (confirm("Are you sure?")) {
                this.deleting = true;

                axios
                    .delete(route("test-creator.media.destroy", [this.resource, this.mediaItem]))
                    .then(() => {
                        this.$emit("deleted", this.mediaItem);
                    })
                    .catch(() => {
                        this.deleting = false;
                    });
            }
        },

        selectImage() {
            if (this.mediaItem.custom_properties.needs_copied) {
                return;
            }

            window.opener.SetUrl([
                {
                    url: this.mediaItem.original_url,
                    name: this.mediaItem.name,
                },
            ]);
        },
    },
};
</script>

<template>
    <div class="group relative ml-6 mt-6 flex w-full flex-col overflow-hidden rounded shadow-lg sm:w-1/2 md:max-w-sm">
        <div class="flex w-full flex-grow flex-col justify-center">
            <img
                :class="imageClasses"
                :src="mediaItem.original_url"
                :alt="mediaItem.file_name"
            />
        </div>

        <button
            @click="deleteMedia"
            :disabled="deleting"
            class="absolute right-0 top-0 hidden h-8 w-8 items-center justify-center rounded-full bg-gray-200 bg-opacity-75 group-hover:flex"
            :class="{ 'text-gray-300': deleting }"
        >
            <FontAwesomeIcon icon="times" />
        </button>

        <div class="relative bottom-0 flex w-full bg-gray-200 bg-opacity-75">
            <div class="flex-grow px-6 py-4 group-hover:max-w-3/4">
                <div class="mb-2 text-lg font-bold">
                    {{ mediaItem.name }}
                </div>

                <div
                    class="mb-2 rounded-full bg-warning px-4 py-3 text-sm font-bold"
                    v-if="mediaItem.custom_properties.needs_copied"
                >
                    Media duplication is still in process. Check back in a few minutes.
                </div>
            </div>

            <div
                class="absolute right-0 hidden h-full items-center justify-center px-3 py-2 group-hover:flex group-hover:bg-gray-200"
            >
                <button
                    @click="selectImage"
                    class="mr-2 inline-block rounded-full bg-primary-lighter px-3 py-1 text-sm font-semibold text-white"
                    :class="mediaItem.custom_properties.needs_copied ? 'cursor-not-allowed opacity-25' : ''"
                >
                    Select
                </button>
            </div>
        </div>
    </div>
</template>
