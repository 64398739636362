<script>
import Draggable from "vuedraggable";
import TestListItem from "@/components/TestCreation/TestListItem.vue";

export default {
    components: {
        Draggable,
        TestListItem,
    },
    data() {
        return {
            tests: [],
            creating: false,
        };
    },

    props: {
        initialTests: {
            default() {
                return [];
            },
        },

        resource: {},
    },

    methods: {
        createTest() {
            this.creating = true;
            const lastTest = _.maxBy(this.tests, "order");
            this.tests.push({
                id: new Date().getTime(),
                title: "",
                order: (lastTest || { order: 0 }).order + 1,
            });
        },

        deleteTest(test) {
            const index = _.findIndex(this.tests, { id: test.id });

            this.tests.splice(index, 1);
        },

        updateTest(test) {
            const index = _.findIndex(this.tests, { id: test.id });

            this.tests.splice(index, 1, test);
        },

        rearrangeTests({ moved }) {
            const { newIndex, oldIndex, element } = moved;

            const url = `/api/resources/${this.resource.id}/tests/${element.id}/reorder`;

            axios.post(url, {
                new_order: newIndex + 1,
                old_order: oldIndex + 1,
            });
        },
    },

    watch: {
        initialTests: {
            handler() {
                this.tests = [...this.initialTests];
            },
            immediate: true,
        },
    },
};
</script>

<template>
    <div>
        <Draggable
            item-key="id"
            class="py-4"
            v-model="tests"
            @change="rearrangeTests"
        >
            <template #item="{ element: test }">
                <TestListItem
                    :resource="resource"
                    :test="test"
                    @created="updateTest"
                    @updated="updateTest"
                    @deleted="deleteTest"
                />
            </template>

            <template #footer>
                <a
                    :href="route('test-creator.tests.create', [resource])"
                    class="flex w-full items-center border border-b-0 px-3 py-2 first:rounded-t last:rounded-b last:border-b"
                >
                    <FontAwesomeIcon
                        icon="plus"
                        class="mr-2"
                    />

                    <span class="italic">Add Test</span>
                </a>
            </template>
        </Draggable>
    </div>
</template>

<style lang="scss" scoped>
.handle {
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}
</style>
