<script>
import CopyButton from "@/components/Global/CopyButton.vue";
import Swal from "sweetalert2";

export default {
    components: {
        CopyButton,
    },

    data() {
        return {
            edit: false,
            hasBeenSaved: false,
        };
    },

    props: {
        resource: {
            type: Object,
            default: () => ({}),
        },

        test: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        isSample() {
            return this.test.has_samples;
        },

        sampleUrl() {
            return route("sample-test.test", [this.test.resource_id, this.test.id]);
        },
    },

    methods: {
        cancel() {
            this.edit = false;
            if (!this.hasBeenSaved && this.test.created_at === undefined) {
                this.$emit("deleted", this.test);
            }
        },

        deleteTest() {
            Swal.fire({
                title: "Are you sure you want to delete this test?",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                icon: "warning",
            }).then(({ value }) => {
                if (value) {
                    const url = route("test-creator.tests.destroy", [this.resource, this.test]);

                    axios
                        .delete(url)
                        .then(() => {
                            this.$emit("deleted", this.test);
                        })
                        .catch((errors) => {
                            console.error(errors);
                        });
                }
            });
        },

        updateTitle(e) {
            this.test.title = e.target.innerText;
        },
    },

    created() {
        if (this.test.created_at === undefined) {
            this.edit = true;
        }
    },
};
</script>

<template>
    <div class="flex border border-b-0 px-3 py-2 first:rounded-t last:rounded-b last:border-b">
        <div
            class="handle mr-2 inline-block"
            aria-label="Change order"
            data-balloon-pos="up"
        >
            <i class="fas fa-bars"></i>
        </div>

        <a
            :href="route('test-creator.tests.edit', [resource, test])"
            class="flex-grow"
            v-if="!edit"
        >
            {{ test.title }}
        </a>

        <p
            contenteditable="true"
            class="flex-grow border-b border-black"
            @blur="updateTitle"
            v-else
        >
            {{ test.title }}
        </p>

        <div class="ml-4">
            <a
                v-if="test.ebook_page_number"
                :href="route('resources.eBook', resource.id) + '?page=' + test.ebook_page_number"
                class="mr-2 hover:text-gray-600"
                target="_blank"
                aria-label="View eBook"
                data-balloon-pos="up"
            >
                <!--                <i class="fas fa-file-pdf"></i>-->
                <FontAwesomeIcon icon="book" />
            </a>

            <CopyButton
                class="mr-2 inline hover:text-gray-600"
                :value="sampleUrl"
                v-if="!edit && isSample"
            ></CopyButton>

            <a
                :href="route('tests.preview', [test.id])"
                class="mr-2 hover:text-gray-600"
                v-if="!edit"
                target="_blank"
                aria-label="Preview test"
                data-balloon-pos="up"
            >
                <FontAwesomeIcon icon="eye"></FontAwesomeIcon>
            </a>

            <button
                class="px-1 hover:text-gray-600"
                @click="deleteTest"
                v-if="!edit"
                :aria-label="`Delete ${test.title}`"
                data-balloon-pos="up"
            >
                <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
            </button>
        </div>
    </div>
</template>

<style scoped>
.handle:hover {
    cursor: grab;
}

.handle:active {
    cursor: grabbing;
}
</style>
