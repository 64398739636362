<script setup lang="ts">
import { router } from "@inertiajs/vue3";
import { onMounted, PropType, ref } from "vue";
import CSelect from "@/components/Form/CSelect.vue";
import PaginationDefault from "@/components/Global/paginationDefault.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import ActivityLog from "@/components/TestCreation/Resources/ActivityLog.vue";
import type activityLog from "@/types/ActivityLog";
import type Paginator from "@/types/Paginator";
import type Resource from "@/types/Resource";
import Loading from "vue-loading-overlay";

import "vue-loading-overlay/dist/css/index.css";

const props = defineProps({
    resource: {
        type: Object as PropType<Resource>,
        required: true,
    },

    logs: {
        type: Object as PropType<Paginator<activityLog>>,
        default: () => ({}),
    },
});

const loading = ref<boolean>(false);
const subjectTypeOptions = {
    "App\\Models\\Page": "Page",
    "App\\Models\\Question": "Question",
    "App\\Models\\SubmissionAnswer": "Submission Answer",
};
const subjectType = ref<string>(route().params?.subjectType ?? "");
const dateRangeOptions = {
    1: "1 Month",
    3: "3 Month",
    6: "6 Month",
    12: "1 year",
};
const dateRange = ref<string>(route().params?.dateRange ?? "");

function searchLogs(page: number) {
    if (loading.value) {
        return;
    }

    loading.value = true;

    const objParams = {
        subjectType: subjectType.value,
        dateRange: dateRange.value,
        page,
    };

    // Remove empty/null attributes from object
    const params = Object.entries(objParams).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

    const url = route("test-creator.resources.activity-logs", {
        resource: props.resource.id,
        ...params,
    });

    router.get(url, {
        only: ["logs"],
        preserveState: true,
        onFinish: () => {
            loading.value = false;
        },
        onError: (error) => {
            console.error(error);
        },
    });
}

function goToPage(page) {
    if (page < 0) {
        return;
    }

    searchLogs(page);
}
</script>

<template>
    <AppLayout>
        <div class="container mx-auto h-full content-start p-6">
            <!-- Loader -->
            <Loading
                v-model:active="loading"
                :can-cancel="false"
                :is-full-page="true"
            />

            <div class="mb-4 py-4 md:flex">
                <!-- Filter by test/page/submissionAnswer -->
                <div class="mt-4 w-full md:w-1/2 lg:mt-0 xl:w-1/4">
                    <CSelect
                        placeholder="- Type -"
                        :options="subjectTypeOptions"
                        v-model="subjectType"
                        label="Type:"
                        @change="searchLogs(1)"
                    />
                </div>
                <!-- Date range -->
                <div class="ml-0 mt-4 w-full md:ml-3 md:w-1/2 lg:mt-0 xl:w-1/4">
                    <CSelect
                        placeholder="- Date Range -"
                        :options="dateRangeOptions"
                        v-model="dateRange"
                        label="Date Range:"
                        @change="searchLogs(1)"
                    />
                </div>
            </div>

            <table
                v-if="logs.data && logs?.data.length > 0"
                class="w-full"
            >
                <thead>
                    <tr class="border border-gray-400 bg-gray-200">
                        <th class="w-32 px-4 py-2 text-left">Type</th>

                        <th class="w-42 px-4 py-2 text-left">Date</th>

                        <th class="px-4 py-2 text-left">Description</th>
                    </tr>
                </thead>

                <tbody>
                    <ActivityLog
                        v-for="log in logs?.data"
                        :log="log"
                        :key="log.id"
                    />
                </tbody>
            </table>

            <div
                v-else
                class="mt-8 text-center"
            >
                No logs found...
            </div>

            <!-- Show pagination if needed -->
            <PaginationDefault
                v-if="logs.links && logs.links.length > 3"
                :data="logs"
                @go-to-page-clicked="goToPage"
                class="mt-6"
            />
        </div>
    </AppLayout>
</template>
