<script lang="ts" setup>
import HeaderBlock from "@/components/Global/HeaderBlock.vue";
import { usePage } from "@inertiajs/vue3";
import * as Sentry from "@sentry/vue";

const page = usePage();

const user = (page.props.auth.user as User) ?? false;
Sentry.setContext("User Type", user.role);

if (user && ["teacher", "test-creator"].includes(user.role)) {
    Sentry.setUser({
        email: user.email,
        name: user.name,
    });
}
</script>

<template>
    <div>
        <HeaderBlock />

        <slot />
    </div>
</template>
