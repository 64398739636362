/* eslint-disable guard-for-in,no-restricted-syntax */
import Errors from "./Errors.js";

export default class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data;

        for (const field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
    }

    /**
     * Load a model for default data
     *
     * @param {object} model
     */
    load(model) {
        for (const field in model) {
            if (Object.prototype.hasOwnProperty.call(this, field)) {
                this[field] = model[field];
            }
        }
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        const data = {};

        for (const property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    /**
     * Reset the form fields.
     */
    reset(defaults = {}) {
        for (const field in this.originalData) {
            this[field] = Object.prototype.hasOwnProperty.call(defaults, field) ? defaults[field] : '';
        }

        this.errors.clear();
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     * @param {object|null} data
     */
    post(url, data = null) {
        return this.submit('post', url, data);
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     * @param {object|null} data
     */
    put(url, data = null) {
        return this.submit('put', url, data);
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     * @param {object|null} data
     */
    patch(url, data = null) {
        return this.submit('patch', url, data);
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     * @param {object|null} data
     */
    delete(url, data = null) {
        return this.submit('delete', url, data);
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     * @param {object|null} data
     */
    submit(requestType, url, data = null) {
        if (!data) {
            data = this.data();
        }

        return new Promise((resolve, reject) => {
            window.axios[requestType](url, data)
                .then((response) => {
                    this.onSuccess(response.data);

                    resolve(response.data);
                })
                .catch((error) => {
                    error = Object.assign({}, error);

                    this.onFail(error.response.data);

                    reject(error.response.data);
                });
        });
    }

    /**
     * Handle a successful form submission.
     *
     * @param {object} data
     */
    onSuccess(data) {
        this.reset();
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail({errors}) {
        if (errors) {
            this.errors.record(errors);
        }
    }
};
