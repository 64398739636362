<script setup lang="ts">
import {PropType, ref} from "vue";
import User from "@/types/User";
import axios from "axios";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";

const props = defineProps({
    user: {
        type: Object as PropType<User>,
        required: true,
    },
});

const {route} = window;
const formPassword = ref({
    processing: false,
    saved: false,
    password: '',
    new_password: '',
    new_password_confirmation: '',
});

function savePassword() {
    formPassword.value.processing = true;
    axios.post(route('reset.password'), {
        password: formPassword.value.password,
        new_password: formPassword.value.new_password,
        new_password_confirmation: formPassword.value.new_password_confirmation,
    }).then(() => {
        formPassword.value.saved = true;
        setTimeout(() => {
            formPassword.value.saved = false;
        }, 5000);
    }).finally(() => {
        formPassword.value.processing = false;
    });
}
</script>

<template>
    <form
        @submit.prevent="savePassword"
        class="flex flex-col items-stretch h-full card">
        <div class="flex flex-col items-start justify-between flex-1 p-4">
            <div
                v-if="formPassword.saved"
                class="relative px-4 py-3 mb-4 text-green-700 bg-green-100 border border-green-400 rounded w-full"
                role="alert">
                <strong class="font-bold">Success!</strong>

                <span class="block sm:inline">You're password has been updated</span>
            </div>

            <div class="flex-grow w-full space-y-4">
                <div class="w-full">
                    <label
                        for="password"
                        class="col-md-4 col-form-label text-md-right">Current Password</label>

                    <div class="">
                        <input
                            v-model="formPassword.password"
                            id="password" type="password" name="password"
                            autocomplete="password" autofocus
                            class="w-full px-3 py-2 leading-tight text-gray-700 border rounded
                                                    shadow appearance-none focus:outline-none focus:shadow-outline"
                            required/>
                    </div>
                </div>

                <div class="w-full">
                    <label
                        for="new_password"
                        class="col-md-4 col-form-label text-md-right">New Password</label>

                    <div class="">
                        <input
                            v-model="formPassword.new_password"
                            id="new_password" type="password" name="new_password"
                            autocomplete="new_password" autofocus
                            class="w-full px-3 py-2 leading-tight text-gray-700 border rounded
                                                    shadow appearance-none focus:outline-none focus:shadow-outline"
                            required/>
                    </div>
                </div>

                <div class="w-full">
                    <label
                        for="new_password_confirmation"
                        class="col-md-4 col-form-label text-md-right">
                        Confirm New Password
                    </label>

                    <div class="">
                        <input
                            v-model="formPassword.new_password_confirmation"
                            id="new_password_confirmation" type="password"
                            name="new_password_confirmation"
                            autocomplete="new_password_confirmation" autofocus
                            class="w-full px-3 py-2 leading-tight text-gray-700 border rounded
                                                    shadow appearance-none focus:outline-none focus:shadow-outline"
                            required/>
                    </div>
                </div>
            </div>

            <BtnPrimary
                class="w-full xl:w-auto xl:px-12 mt-8 mx-auto"
                :disable="formPassword.processing">Reset Password
            </BtnPrimary>
        </div>
    </form>
</template>

<style scoped>

</style>
