export default Object.freeze({
    states: {
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        'DC': 'District of Columbia',
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
    },
    grades: {
        "k": "Kindergarten",
        "1": "1st",
        "2": "2nd",
        "3": "3rd",
        "4": "4th",
        "5": "5th",
        "6": "6th",
        "7": "7th",
        "8": "8th",
        "9": "9th",
        "10": "10th",
        "11": "11th",
        "12": "12th"
    },
    topics: {
        "math": "Math",
        "history": "Social Studies",
        "science": "Science",
        "english": "English",
    },
    hearAboutUsOptions: {
        "postCard": "Post Card",
        "catalog": "Catalog",
        "email": "E-mail",
        "phoneCall": "Phone Call",
        "educator": "A Fellow Educator",
        "conference": "Conference/Convention",
        "website": "Web Site",
        "searchEngin": "Search Engine",
        "wordOfMouth": "Word of Mouth",
        "other": "Other (Please add in comments)",
    },
    keyboards: {
        "all": "All keyboards",
        "numeric": "Basic",
        "numeric roman": "Algebra",
        "numeric roman functions symbols greek": "Calculus/Trigonometry",
        "science": "Science",
        "elementary": "Elementary School",
        "middleHigh": "Middle/High School",
    },
    roles: {
        'student': 'student',
        'teacher': 'teacher',
        'testCreator': 'test-creator',
    },
});
